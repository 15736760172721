<script setup lang="ts">
  import { reactive } from 'vue'

  interface ModalProps {
    closeBtnFlg: boolean,
    modalMarginX: string,
    headerText: string,
    hiddenFooterLine: boolean,
    isOriginal: boolean,
    role: string,
    modalHeight: string,
    modalMaxWidth: string,
    bodyScrollFlg: boolean,
  }

  const props = withDefaults(defineProps<ModalProps>(), {
    closeBtnFlg: true,
    hiddenFooterLine: false,
    isOriginal: false,
    modalHeight: 'h-[75vh]',
    modalMaxWidth: '',
    bodyScrollFlg: true,
  })

  const emits = defineEmits<{
    (e: 'close', value: string): void
  }>()

  const closeBtnFlg = props.closeBtnFlg !== undefined ? props.closeBtnFlg : true

  let closeBtnBoxClass = [
    'my-4',
    'pr-4',
  ]

  let headerClass = [
    {
      'common-background-pink' : props.role  === 'manager',
      'common-background-leaf' : props.role === 'guard',
    },
    'h-16',
    'items-center',
    'flex',
    'font-semibold',
    'rounded-t-xl',
    'text-white',
    'md:text-xl',
    'top-0',
    'w-full',
  ]

  let modalBoxClass = [
    props.isOriginal ? '' : 'max-h-[75vh]',
    props.modalHeight ? props.modalHeight : 'h-screen',
    props.modalMaxWidth,
    'flex',
    'flex-col',
    'rounded-2xl',
    'w-full',
  ]

  const closeBtnSetting = reactive({
    bgColor: "transparent",
    borderColor: "white",
    btnSize: "medium",
    iconColor: "white",
  })

  headerClass = headerClass.concat(closeBtnFlg ? 'justify-between': 'justify-center')
  modalBoxClass.push(props.modalMarginX)

</script>
<template>
  <!-- 背景 -->
  <div
    class="
      fixed
      bg-black
      bg-opacity-40
      flex
      h-screen
      items-center
      justify-center
      top-0
      w-screen
      z-common-modal
    "
  >
    <!-- モーダル本体 -->
    <div
      :class="modalBoxClass"
    >
      <!-- Header -->
      <div
        :class="headerClass"
      >
        <div
          v-if="closeBtnFlg"
          class="
            h-8
            ml-4
            w-8
          "
        >
        </div>
        <span
          class="
            p-3
            text-center
          "
        >
          {{ headerText }}
        </span>
        <!-- 閉じるボタン -->
        <div
          v-if="closeBtnFlg"
          :class="closeBtnBoxClass"
        >
          <button
            class="
              h-8
              w-8
            "
            @click="emits('close', 'close modal')"
          >
            <common-btn-close
              :setting="closeBtnSetting"
            >
            </common-btn-close>
          </button>
        </div>
      </div>
      <!-- Body -->
      <div
        class="
          bg-white
          flex-grow
          overscroll-none
        "
        :class="{
          'pt-8' : !props.isOriginal && props.hiddenFooterLine,
          'py-8' : !props.isOriginal && !props.hiddenFooterLine,
          'px-10' : !props.isOriginal,
          'overflow-y-scroll' : props.bodyScrollFlg,
          'overflow-y-none' : !props.bodyScrollFlg,
        }"
      >
        <slot name="body"/>
      </div>
      <!-- Footer -->
      <div
        class="
          bg-white
          bottom-0
          flex
          items-center
          justify-center
          rounded-b-xl
          w-full
        "
        :class="
          props.hiddenFooterLine ? 'h-4' : 'border-t border-gray-200 h-16 md:h-24'
        "
      >
        <slot name="control"/>
      </div>
    </div>
  </div>
</template>
